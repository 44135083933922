import React from "react";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import { LogoLink } from "components/headers/light.js";
import logo from "../images/logo.png";
import {
  AMAZON_AUTHOR_PAGE,
  AUDIBLE_AUTHOR_PAGE,
  BOOKSPROUT_TEAM_PAGE,
} from "../data";

//#region TailwindCSS
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;

//#endregion

export default () => {
  return (
    <NavRow>
      <LogoLink href="/">
        <img src={logo} alt="" />
        Edgar Wise
      </LogoLink>
      <div tw="flex flex-wrap justify-center lg:justify-end items-center -mr-12">
        <NavLink target="_blank" href={BOOKSPROUT_TEAM_PAGE}>
          Join my advance reader team
        </NavLink>
        <NavLink target="_blank" href={AUDIBLE_AUTHOR_PAGE}>
          Audible Profile
        </NavLink>
        <div tw="md:hidden flex-100 h-0"></div>
        <PrimaryNavLink target="_blank" href={AMAZON_AUTHOR_PAGE}>
          Amazon Profile
        </PrimaryNavLink>
      </div>
    </NavRow>
  );
};
