import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import AnchorLink from "react-anchor-link-smooth-scroll";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import HeroImage from "./images/hero.png";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";

//#region TailwindCSS
const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)``;

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg mr-2`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
//#endregion

export default () => {
  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Collection of{" "}
              <span tw="text-primary-500">free ebooks for you.</span>
            </Heading>
            <Paragraph>
              Scroll down and find the "Download Free eBooks" section to start
              downloading now! If you like what you've read, be sure to check
              out my other books as well.
            </Paragraph>
            <Paragraph>
              <PrimaryButton href="#freeEbooks">Get Free eBooks</PrimaryButton>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src={HeroImage}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
