import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { motion } from "framer-motion";

import Hero from "./Hero.js";
import SectionTitles from "./SectionTitles";
import Footer from "./shared/Footer.js";
import Menu from "./shared/Menu";
import * as data from "./data";
import {
  Container,
  Content2Xl,
  ContentWithVerticalPadding,
} from "components/misc/Layouts";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const SectionContainer = tw(ContentWithVerticalPadding)``;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, []);

  return (
    <StyledDiv>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <AnimationRevealPage>
          <Content2Xl>
            <Menu />
            <Hero roundedHeaderButton={true} />
          </Content2Xl>
          <Content2Xl>
            <SectionContainer id="freeEbooks">
              <SectionTitles
                tabs={data.freeEbooks}
                heading={
                  <>
                    Download <HighlightedText>Free eBooks</HighlightedText>
                  </>
                }
                btnText="Download"
                onCardClick={(e, card) => {
                  if (!card.imageSrc.book) {
                    e.preventDefault();
                  }
                }}
              />
            </SectionContainer>
          </Content2Xl>
          <Content2Xl>
            <SectionTitles
              tabs={data.titles}
              heading={
                <>
                  Discover <HighlightedText>Books</HighlightedText>
                </>
              }
              onCardClick={(e, card) => {
                if (!card.kdp.imageSrc) {
                  e.preventDefault();
                }
              }}
            />
          </Content2Xl>

          <SectionTitles
            tabs={data.titles}
            heading={
              <>
                Download <HighlightedText>Free Audiobooks</HighlightedText>
              </>
            }
            titleTypeProp="acx"
            btnText="Download for Free"
            onCardClick={(e, card) => {
              e.preventDefault();
              if (card.acx.imageSrc) {
                window.location = `/free-audiobook/${card.siteCode}`;
              }
            }}
            CardComponent={tw(
              motion.a
            )`w-64 xl:w-72 bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`}
            CardImageContainerComponent={styled.div`
              ${(props) =>
                css`
                  background-image: url("${props.imageSrc}");
                `}
              ${tw`w-64 h-64 xl:w-72 xl:h-72 bg-center bg-cover relative rounded-t`}
            `}
          />
        </AnimationRevealPage>
        <Footer />
      </Container>
    </StyledDiv>
  );
};
