const AUDIBLE_AUTHOR_PAGE =
  "https://www.audible.com/author/Edgar-Wise/B09DBZQ935";
const AMAZON_AUTHOR_PAGE = "https://amazon.com/author/edgarwise";
const BOOKSPROUT_TEAM_PAGE =
  "https://booksprout.co/reviewer/team/23181/edgar-wises-review-team";

const NICHE = { MEN: "Men" };

const getBountyLinks = (us, ca, fr, de) => {
  return [
    {
      name: "Audible US",
      url: us,
    },
    {
      name: "Audible CA",
      url: ca,
    },
    {
      name: "Audible FR",
      url: fr,
    },
    {
      name: "Audible DE",
      url: de,
    },
  ];
};

const BTN_TEXT_DOWNLOAD = "Download";
const BTN_TEXT_BUY_ON_AMAZON = "Buy on Amazon";
const freeEbooks = {
  "For Men": [
    {
      btnText: BTN_TEXT_DOWNLOAD,
      niche: NICHE.MEN,
      kdp: {
        imageSrc:
          "https://bookfunnelimages.com/images/covers/g3ivl8a4sg/cover_md.jpg",
        url: "https://dl.bookfunnel.com/6ntkfhaqac",
      },
    },
    {
      btnText: BTN_TEXT_DOWNLOAD,
      niche: NICHE.MEN,
      kdp: {
        imageSrc:
          "https://bookfunnelimages.com/images/covers/n3nx0p0xua/cover_md.jpg",
        url: "https://dl.bookfunnel.com/fuyo56hba8",
      },
    },
    {
      btnText: BTN_TEXT_DOWNLOAD,
      niche: NICHE.MEN,
      kdp: {
        imageSrc:
          "https://bookfunnelimages.com/images/covers/k4k7ozitiy/cover_md.jpg",
        url: "https://dl.bookfunnel.com/oi2kotlz2a",
      },
    },
    {
      btnText: BTN_TEXT_DOWNLOAD,
      niche: NICHE.MEN,
      kdp: {
        imageSrc:
          "https://bookfunnelimages.com/images/covers/jchalfbcqn/cover_md.jpg",
        url: "https://dl.bookfunnel.com/u05av6sua2",
      },
    },
    {
      btnText: BTN_TEXT_DOWNLOAD,
      niche: NICHE.MEN,
      kdp: {
        imageSrc:
          "https://bookfunnelimages.com/images/covers/y4l30lvomj/cover_md.jpg",
        url: "https://dl.bookfunnel.com/3xgjchbztw",
      },
    },
  ],
};

const titles = {
  "For Men": [
    {
      name: "Self-Love Workbook for Men",
      siteCode: "slw4m",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.MEN,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/41OGWkXp9tL.jpg",
        url: "https://mybook.to/slw4m",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/41wuf-yq+oL._SL500_.jpg",
        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B09M561ZTF/?source_code=AUDFPWS0223189MWT-BK-ACX0-286262&ref=acx_bty_BK_ACX0_286262_rh_us",
          "https://www.audible.co.uk/pd/B09M4Y8GDV/?source_code=AUKFrDlWS02231890H6-BK-ACX0-286262&ref=acx_bty_BK_ACX0_286262_rh_uk",
          "https://www.audible.fr/pd/B09M6MYV9B/?source_code=FRAORWS022318903B-BK-ACX0-286262&ref=acx_bty_BK_ACX0_286262_rh_fr",
          "https://www.audible.de/pd/B09M6CHV7D/?source_code=EKAORWS0223189009-BK-ACX0-286262&ref=acx_bty_BK_ACX0_286262_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98182/self-love-workbook-for-men-recognize-your-worth-build-self-confidence-overcome-self-doubt-and-embrace-who-you-are",
      },
    },
    {
      name: "Men With Adult ADHD",
      siteCode: "mwaa",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.MEN,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/41Epw2E+MfL.jpg",
        url: "https://mybook.to/mwaa",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/41i17uqN1IL._SL500_.jpg",
        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B09XXJ8PG2/?source_code=AUDFPWS0223189MWT-BK-ACX0-305018&ref=acx_bty_BK_ACX0_305018_rh_us",
          "https://www.audible.co.uk/pd/B09XXJHZYB/?source_code=AUKFrDlWS02231890H6-BK-ACX0-305018&ref=acx_bty_BK_ACX0_305018_rh_uk",
          "https://www.audible.fr/pd/B09XXHMD74/?source_code=FRAORWS022318903B-BK-ACX0-305018&ref=acx_bty_BK_ACX0_305018_rh_fr",
          "https://www.audible.de/pd/B09XXGXR59/?source_code=EKAORWS0223189009-BK-ACX0-305018&ref=acx_bty_BK_ACX0_305018_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98185/men-with-adult-adhd-improve-concentration-increase-productivity-and-stop-feeling-like-a-failure",
      },
    },
    {
      name: "How to Set Healthy Boundaries",
      siteCode: "h2shb",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.MEN,
      kdp: {
        imageSrc:
          "https://m.media-amazon.com/images/I/81J4eGLGNzL._SL1500_.jpg",
        url: "https://mybook.to/h2shb",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51635AgWhOL._SL500_.jpg",
        bountyLinks: getBountyLinks("", "", "", ""),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/149429/how-to-set-healthy-boundaries",
      },
    },
    {
      name: "Fix Overthinking in Relationships",
      siteCode: "foir2",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.MEN,
      kdp: {
        imageSrc:
          "https://m.media-amazon.com/images/I/8109gKsjjtL._SL1500_.jpg",
        url: "https://mybook.to/foir2",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51KGToYxqIL._SL500_.jpg",
        bountyLinks: getBountyLinks("", "", "", ""),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/135570/fix-overthinking-in-relationships-eliminate-toxic-thoughts-stop-sabotaging-your-relationships-keep-your-sanity-and-feel-more-connected-to-your-partner",
      },
    },
    {
      name: "Healing Anxious Attachment",
      siteCode: "hanxa",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.MEN,
      kdp: {
        imageSrc:
          "https://m.media-amazon.com/images/I/81Y4auKAgAL._SL1500_.jpg",
        url: "https://mybook.to/hanxa",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51uCWQX4vRL._SL500_.jpg",
        bountyLinks: getBountyLinks("", "", "", ""),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/170756/healing-anxious-attachment",
      },
    },
  ],
};

export {
  freeEbooks,
  titles,
  AUDIBLE_AUTHOR_PAGE,
  AMAZON_AUTHOR_PAGE,
  BOOKSPROUT_TEAM_PAGE,
  NICHE,
};
